
import subscribeNewsletter from '~/apollo/mutation/subscribeEmailToNewsletter'

export default {
  name: 'newsletter',
  components: {
    Wrapper: () => import('@/components/Structure/Wrapper'),
  },
  computed: {
    cssProps() {
      return {
        '--bg-color': this.bgColors,
        '--txt-color': this.txtColors
      }
    }
  },
  props: {
    bgColors: {
      type: String,
      default: '#FFFFFF'
    },
    txtColors: {
      type: String,
      default: '#000000'
    }
  },
  data () {
    return {
      tokenRecaptcha: '',
      newsletter: {
        gender: '',
        email: '',
        name: '',
        telephone: '',
        origin: 'footer_newsletter'
      },
      success: false
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$recaptcha.init()
      } catch (e) {
        console.log(e)
      }
      this.tokenRecaptcha = await this.$recaptcha.execute('newsletter')
      const isValid = await this.$validator.validateAll()
      if (isValid && this.tokenRecaptcha !== '') {
        try {
          await this.$apollo.mutate({
            mutation: subscribeNewsletter,
            variables: {
              email: this.newsletter.email,
              gender: this.newsletter.gender,
              telephone: this.newsletter.telephone,
              from_source: 'footer_newsletter'
            },
            context: {
              headers: {
                'x-recaptcha': `${this.tokenRecaptcha}`
              }
            }
          }).then((data) => {
            this.success = true
            try {
              this.$recaptcha.reset()
            } catch (e) {
              console.log(e)
            }
            setTimeout(() => {
              this.tokenRecaptcha = ''
              this.newsletter.email = ''
              this.newsletter.gender = ''
              this.newsletter.telephone = ''
              this.$validator.pause()
              this.success = false
              this.$nextTick(() => {
                this.$validator.errors.clear()
                this.$validator.fields.items.forEach(field => field.reset())
                this.$validator.fields.items.forEach(field => this.errors.remove(field))
                this.$validator.resume()
              })
            }, 5000)
          }).catch(({ graphQLErrors }) => {
            console.log('Error')
          })
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
}
